import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/reverbdesigns'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/company/reverb-designs/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/reverbdesigns'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/reverbdesign'},
]

class DescriptiveDesign extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='MissingLink' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Descriptive Design</h2>
                                    <p>The creative combination of content and context</p><p>leads to thought provoking form.
 </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>Engaing visual experiences</h2>
                                        <p className="subtitle">Lead to higher engamement rates, brand recognition and a loyal client base.
  </p>

                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Language</span>
                                                <h4 style={{paddingTop: 20 + 'px'}}>Photoshop &amp; Illustrator </h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Types</span>
                                                <h4 style={{paddingTop: 20 + 'px'}}>Graphic Design</h4>
                                            </div>
                                        </div>
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/rebus.jpg" alt="Typography rebus"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/invisible.jpg" alt="Discreet messaging"/>
                                        </div>
                                        
                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/rebranding.jpg" alt="Contextual messaging"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/solvingproblems.jpg" alt="Imagry and content"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

              
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default DescriptiveDesign;
